.form-container {
    background: linear-gradient(#020617, #020617) padding-box,
                linear-gradient(145deg, transparent 35%,rgba(134,239,172,1), rgba(2,6,23,1) 100%) border-box;
    border: 2px solid transparent;
    
    border-radius: 16px;
    box-sizing: border-box;
    background-size: 200% 100%;
    animation: gradient 4s ease infinite;
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
  
    50% {
      background-position: 100% 50%;
    }
  
    100% {
      background-position: 0% 50%;
    }
  }